import { useState, useEffect } from "react";
import * as React from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Layout from "./Layout/Layout";
import dayjs from "dayjs";

// import { addUserDetails, saveSignature } from "../redux/actions/registerCustomerAction";
import { useForm } from "react-hook-form";
import { Form, Button } from "semantic-ui-react";
import { addNewUserDetails } from "../redux/actions/registerCustomerAction";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
//import { set } from "react-hook-form";
export const UserDetails = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();



  const dummyData = useSelector(
    (state) => state.addNewUserDetailsReducer.newUserDetailsForm
  );

  const navigate = useNavigate();

  let isReputablePerson = useSelector(
    (state) => state.addNewReputablePersonDetails.reputableDetails
  );

  const testReputablePersonInd =
    isReputablePerson !== null ? isReputablePerson.isReputablePerson : 0;
  // const validateDetailsData = useSelector(state=>state.addNewUserDetailsReducer);
  // console.log(validateDetailsData);

  const customerDetails = useSelector(
    (state) => state.addNewUserDetailsReducer.validateCustomerDetails
  );

  const primaryData = useSelector(
    (state) => state.saveDocumentReducer.primaryIdData
  );

  const id_Num = customerDetails !== null ? customerDetails.id_Num : null;

  const fName = dummyData !== null ? dummyData.firstName : null;
  const mName = dummyData !== null ? dummyData.middleName : null;
  const lName = dummyData !== null ? dummyData.lastName : null;
  const dob = dummyData !== null ? dummyData.dateOfBirth : null;
  const displaydob = dummyData !== null ? dummyData.displaydateOfBirth : null;
  const gender = dummyData !== null ? dummyData.Gender : null;

  const citizenshipType = dummyData !== null ? dummyData.citizenship : null;
  const country = dummyData !== null ? dummyData.country : null;

  const province = dummyData !== null ? dummyData.Area : null;
  const selOccupation = dummyData !== null ? dummyData.OccupationIndustry : null;
  const residentialAddress = dummyData !== null ? dummyData.ResidentialAddress : null;
  const selEmail = dummyData !== null ? dummyData.EmailAddress : null;
  const altContact = dummyData !== null ? dummyData.Contact : null;
  //const isReputable = isReputablePerson !==null?isReputablePerson.reputablePerson:null;
  const [firstName, setfirstName] = useState(fName !== null ? fName : "");
  const [middleName, setMiddleName] = useState(mName !== null ? mName : "");
  const [lastName, setLastName] = useState(lName !== null ? lName : "");
  const [dateOfBirth, setDateOfBirth] = useState(dob !== null ? dob : "");

  const [displayDOB, setDisplayDOB] = useState(
    displaydob !== null ? displaydob : ""
  );

  const [Gender, setGender] = React.useState(gender !== null ? gender : "");

  const [citizenship, setCitizenship] = useState(citizenshipType !== null ? citizenshipType : "");

  const [Area, setArea] = useState(province !== null ? province : "");

  const [Country, setCountry] = useState(country !== null ? country : "");

  const [OccupationIndustry, setOccupationIndustry] = useState(
    selOccupation !== null ? selOccupation : ""
  );
  const [ResidentialAddress, setResidentialAddress] = useState(
    residentialAddress !== null ? residentialAddress : ""
  );
  const [EmailAddress, setEmailAddress] = useState(
    selEmail !== null ? selEmail : ""
  );
  const [Contact, setContact] = useState(altContact !== null ? altContact : "");
  //const [reputablePerson, setReputablePerson] = useState (isReputable!==null ? isReputable: "");
  const [isMale, setIsMale] = React.useState(() => {
    return gender === null
      ? null
      : gender === "male"
      ? true
      : gender === "female"
      ? false
      : null;
  });
  const [isFijian, setIsFijian] = React.useState(() => {
    return citizenshipType === null
      ? null
      : citizenshipType === "citizen"
      ? true
      : citizenshipType === "non-citizen"
      ? false
      : null;
  });

  // const reputableChecked = useState (isReputablePerson !== null ? isReputablePerson.isReputablePerson : 0);
  // console.log(reputableChecked);
  //declaration of useDispatch function
  const dispatch = useDispatch();

  //OnClick dispatch function to save all information

  // firstName,
  // middleName,
  // lastName,
  // dateOfBirth,
  // Gender,
  // citizenship,
  // Area,
  // OccupationIndustry,
  // ResidentialAddress,
  // EmailAddress,
  // Contact

  //Setter functions for textfields
  const onChangeFirstName = (firstname) => {
    setfirstName(firstname);
  };

  const onChangeMiddleName = (name) => {
    setMiddleName(name);
  };

  const onChangeLastName = (lname) => {
    setLastName(lname);
  };

  let areanum = citizenship;
  let numstring = areanum.toString;

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handlemiddleNameChange = (event) => {
    setMiddleName(event.target.value);
  };

  const handlelastnameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleDobnameChange = (Newvalue) => {
    setDateOfBirth(Newvalue);
  };

  const handleAreaChange = (event) => {
    setArea(event.target.value);
  };

  const handleCountryChange = (event) => {
    setArea(event.target.value);
  };

  const handleOccupationChange = (event) => {
    setOccupationIndustry(event.target.value);
  };
  const onChangeEmail = (email) => {
    setEmailAddress(email);
  };

  const onChangeContact = (contact) => {
    setContact(contact);
  };
  const onChangeResidentialAddress = (address) => {
    setResidentialAddress(address);
  };

  const handleDateChange = (value) => {
    try {
      if (value) {
        let dob = moment(value.toISOString()).format("DD/MM/YYYY");
        console.log(dob);
        setDateOfBirth(dob);
      }
      setDisplayDOB(value);
    } catch (error) {
      console.error(error);
      // You can display an error message to the user or handle the error in some other way
    }
  };

  const saveUserDetails = () => {
    let dummyCusData = {};
    dummyCusData.firstName = firstName;
    dummyCusData.middleName = middleName;
    dummyCusData.lastName = lastName;
    dummyCusData.dateOfBirth = dateOfBirth;
    dummyCusData.displaydateOfBirth = displayDOB;
    dummyCusData.Gender = Gender;
    dummyCusData.citizenship = citizenship;
    dummyCusData.Area = Area;
    dummyCusData.country = Country;
    dummyCusData.OccupationIndustry = OccupationIndustry;
    dummyCusData.ResidentialAddress = ResidentialAddress;
    dummyCusData.EmailAddress = EmailAddress;
    dummyCusData.Contact = Contact;

    dispatch(addNewUserDetails(dummyCusData));
    //dispatch ();
  };

  useEffect(() => {
    if (citizenship === 1) {
      setCountry('Fiji');
    }
  }, [Country]);

  console.log("Country",Country)

  const onSubmit = async (data) => {
    saveUserDetails();

    if (!displayDOB) {
      alert("Please select a date of birth");
      return;

    }

     await axios({
      url: "https://mpsreg-api.azurewebsites.net/api/registration/getBlacklistCustomer",
      method: "POST",
      data: {
        UserID: "100",
        CustomerFirstName: firstName,
        CustomerLastName: lastName,
        CustomerDOB:dateOfBirth,
        CustomerIDNumber: id_Num,
        api_key: "YouShallPass",
      },
    })
      .then((response) => {
        console.log(response.data.Status)
        console.log(response.data.responseCode)

        if (response.data.responseCode === 200 ) {

          alert("This Customer is blacklisted! Pleae Contact Vodafone Fiji Customer care.");
          navigate("/validation");
          return;
        } 
        else if (response.data.responseCode === 404 )
        alert("Customer is eligible to proceed for registration")

        if(testReputablePersonInd === 1)
        {
         navigate("/parentGuardianEndorser");
        }else {
        navigate("/securityQuestions");
        }
      })

      .catch((e) => {
        alert("Service Unavailable, Please contact customer care. Thank You.");

      });
  };

  return (
    <Layout>
      <React.Fragment>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-Pg3">
            <div>
              <label className="mb-4">
                <b>Personal Details</b>
              </label>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                autoComplete="on"
              >
                <div className="side-by-side">
                  <TextField
                    id="standard-required"
                    label="First Name"
                    type="text"
                    value={firstName}
                    {...register("firstName", {
                      required: true,
                      maxLength: 20,
                      pattern: /^[a-zA-Z]+$/,
                    })}
                    onChange={(input) => {
                      onChangeFirstName(input.target.value);
                    }}
                  />

                  {errors.firstName && (
                    <p className="text-error" style={{ fontSize: "10px" }}>
                      <br></br>Enter a valid First Name
                    </p>
                  )}

                  <TextField
                    id="standard"
                    label="Middle Name"
                    type="text"
                    value={middleName}
                    size="large"
                     {...register("middleName", { 
                      required: false, 
                      maxLength: 20,
                      pattern: /^[a-zA-Z]+$/,
                      
                    })}
                    onChange={(input) => {
                      onChangeMiddleName(input.target.value);
                    }}
                  />

                  <TextField
                    id="standard"
                    label="Last Name"
                    type="text"
                    value={lastName}
                    {...register("lastName", { 
                      required: true, 
                      maxLength: 20,
                      pattern: /^[a-zA-Z]+$/,
                      
                    })}
                    onChange={(input) => {
                      onChangeLastName(input.target.value);
                    }}
                  />

                  {errors.lastName && (
                    <p className="text-error" style={{ fontSize: "10px" }}>
                      <br></br>Enter Your Last Name
                    </p>
                  )}
                </div>

                <div className="row-lg-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={displayDOB}
                      inputFormat="DD/MM/YYYY"
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} required
                        error={!displayDOB} // Set error prop based on the existence of displayDOB
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {/* {!displayDOB && (
                      <p className="text-error" style={{ fontSize: "10px" }}>
                        <br></br>  Please select a date
                      </p>
                    )} */}

                  <div className="row-lg-2">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={Gender}
                        {...register("gender", { required: true })}
                        // onchange={handleGenderChange}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value={"female"}
                          control={<Radio />}
                          label="Female"
                          onChange={() => {
                            setIsMale(false);
                          }}
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                          onChange={() => {
                            setIsMale(true);
                          }}
                          {...register("gender", { required: true })}
                        />

                        {errors.gender && (
                          <p
                            className="text-error"
                            style={{ fontSize: "10px" }}
                          >
                            <br></br>Select Your Gender
                          </p>
                        )}
                      </RadioGroup>
                    </FormControl>

                    <div className="row-lg-2">
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Citizenship
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={citizenship}
                          {...register("citizenship", { required: true })}
                          onChange={(e) => {
                            setCitizenship(parseInt(e.target.value));
                          }}
                        >
                          <FormControlLabel
                            value="1"
                            // checked = {citizenship ===1}
                            control={<Radio />}
                            label="Citizen"
                            onChange={() => {
                              setIsFijian(true);
                              setCountry('Fiji');
                            }}
                          />

                          <FormControlLabel
                            value="0"
                            // checked= {citizenship === 0}
                            control={<Radio />}
                            label="Non-Citizen"
                            {...register("citizenship", { required: true })}
                            onChange={() => {
                              setIsFijian(false);
                            }}
                          />

                          {errors.citizenship && (
                            <p
                              className="text-error"
                              style={{ fontSize: "10px" }}
                            >
                              <br></br>Select Your Citizenship
                            </p>
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Box>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                // noValidate
                autoComplete="off"
              >
                <div>
                  <label className=" mt-4 mb-4">
                    <b>Address Information </b>
                  </label>
                </div>

                <div>

                {citizenship === 0 && (
                    <FormControl sx={{ m: 1, minWidth: 180 }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={Country}
                        label="Country"
                        {...register("Country", { required: true })}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                      >
                      <MenuItem value = "Afghanistan">Afghanistan</MenuItem>
                      <MenuItem value = "Albania">Albania</MenuItem>
                      <MenuItem value = "Algeria">Algeria</MenuItem>
                      <MenuItem value = "Andorra">Andorra</MenuItem>
                      <MenuItem value = "Angola">Angola</MenuItem>
                      <MenuItem value = "Antigua & Deps">Antigua & Deps</MenuItem>
                      <MenuItem value = "Argentina">Argentina</MenuItem>
                      <MenuItem value = "Armenia">Armenia</MenuItem>
                      <MenuItem value = "Australia">Australia</MenuItem>
                      <MenuItem value = "Austria">Austria</MenuItem>
                      <MenuItem value = "Azerbaijan">Azerbaijan</MenuItem>
                      <MenuItem value = "Bahamas">Bahamas</MenuItem>
                      <MenuItem value = "Bahrain">Bahrain</MenuItem>
                      <MenuItem value = "Bangladesh">Bangladesh</MenuItem>
                      <MenuItem value = "Barbados">Barbados</MenuItem>
                      <MenuItem value = "Belarus">Belarus</MenuItem>
                      <MenuItem value = "Belgium">Belgium</MenuItem>
                      <MenuItem value = "Belize">Belize</MenuItem>
                      <MenuItem value = "Benin">Benin</MenuItem>
                      <MenuItem value = "Bhutan">Bhutan</MenuItem>
                      <MenuItem value = "Bolivia">Bolivia</MenuItem>
                      <MenuItem value = "Bosnia Herzegovina">Bosnia Herzegovina</MenuItem>
                      <MenuItem value = "Botswana">Botswana</MenuItem>
                      <MenuItem value = "Brazil">Brazil</MenuItem>
                      <MenuItem value = "Brunei">Brunei</MenuItem>
                      <MenuItem value = "Bulgaria">Bulgaria</MenuItem>
                      <MenuItem value = "Burkina">Burkina</MenuItem>
                      <MenuItem value = "Burundi">Burundi</MenuItem>
                      <MenuItem value = "Cambodia">Cambodia</MenuItem>
                      <MenuItem value = "Cameroon">Cameroon</MenuItem>
                      <MenuItem value = "Canada">Canada</MenuItem>
                      <MenuItem value = "Cape Verde">Cape Verde</MenuItem>
                      <MenuItem value = "Central African Rep">Central African Rep</MenuItem>
                      <MenuItem value = "Chad">Chad</MenuItem>
                      <MenuItem value = "Chile">Chile</MenuItem>
                      <MenuItem value = "China">China</MenuItem>
                      <MenuItem value = "Colombia">Colombia</MenuItem>
                      <MenuItem value = "Comoros">Comoros</MenuItem>
                      <MenuItem value = "Congo">Congo</MenuItem>
                      <MenuItem value = "Congo {Democratic Rep}">Congo (Democratic Rep)</MenuItem>
                      <MenuItem value = "Costa Rica">Costa Rica</MenuItem>
                      <MenuItem value = "Croatia">Croatia</MenuItem>
                      <MenuItem value = "Cuba">Cuba</MenuItem>
                      <MenuItem value = "Cyprus">Cyprus</MenuItem>
                      <MenuItem value = "Czech Republic">Czech Republic</MenuItem>
                      <MenuItem value = "Denmark">Denmark</MenuItem>
                      <MenuItem value = "Djibouti">Djibouti</MenuItem>
                      <MenuItem value = "Dominica">Dominica</MenuItem>
                      <MenuItem value = "Dominican Republic">Dominican Republic</MenuItem>
                      <MenuItem value = "East Timor">East Timor</MenuItem>
                      <MenuItem value = "Ecuador">Ecuador</MenuItem>
                      <MenuItem value = "Egypt">Egypt</MenuItem>
                      <MenuItem value = "El Salvador">El Salvador</MenuItem>
                      <MenuItem value = "Equatorial Guinea">Equatorial Guinea</MenuItem>
                      <MenuItem value = "Eritrea">Eritrea</MenuItem>
                      <MenuItem value = "Estonia">Estonia</MenuItem>
                      <MenuItem value = "Ethiopia">Ethiopia</MenuItem>
                      <MenuItem value = "Fiji">Fiji</MenuItem>
                      <MenuItem value = "Finland">Finland</MenuItem>
                      <MenuItem value = "France">France</MenuItem>
                      <MenuItem value = "Gabon">Gabon</MenuItem>
                      <MenuItem value = "Gambia">Gambia</MenuItem>
                      <MenuItem value = "Georgia">Georgia</MenuItem>
                      <MenuItem value = "Germany">Germany</MenuItem>
                      <MenuItem value = "Ghana">Ghana</MenuItem>
                      <MenuItem value = "Greece">Greece</MenuItem>
                      <MenuItem value = "Grenada">Grenada</MenuItem>
                      <MenuItem value = "Guatemala">Guatemala</MenuItem>
                      <MenuItem value = "Guinea">Guinea</MenuItem>
                      <MenuItem value = "Guinea-Bissau">Guinea-Bissau</MenuItem>
                      <MenuItem value = "Guyana">Guyana</MenuItem>
                      <MenuItem value = "Haiti">Haiti</MenuItem>
                      <MenuItem value = "Honduras">Honduras</MenuItem>
                      <MenuItem value = "Hungary">Hungary</MenuItem>
                      <MenuItem value = "Iceland">Iceland</MenuItem>
                      <MenuItem value = "India">India</MenuItem>
                      <MenuItem value = "Indonesia">Indonesia</MenuItem>
                      <MenuItem value = "Iran">Iran</MenuItem>
                      <MenuItem value = "Iraq">Iraq</MenuItem>
                      <MenuItem value = "Ireland {Republic}">Ireland Republic</MenuItem>
                      <MenuItem value = "Israel">Israel</MenuItem>
                      <MenuItem value = "Italy">Italy</MenuItem>
                      <MenuItem value = "Ivory Coast">Ivory Coast</MenuItem>
                      <MenuItem value = "Jamaica">Jamaica</MenuItem>
                      <MenuItem value = "Japan">Japan</MenuItem>
                      <MenuItem value = "Jordan">Jordan</MenuItem>
                      <MenuItem value = "Kazakhstan">Kazakhstan</MenuItem>
                      <MenuItem value = "Kenya">Kenya</MenuItem>
                      <MenuItem value = "Kiribati">Kiribati</MenuItem>
                      <MenuItem value = "Korea North">Korea North</MenuItem>
                      <MenuItem value = "Korea South">Korea South</MenuItem>
                      <MenuItem value = "Kosovo">Kosovo</MenuItem>
                      <MenuItem value = "Kuwait">Kuwait</MenuItem>
                      <MenuItem value = "Kyrgyzstan">Kyrgyzstan</MenuItem>
                      <MenuItem value = "Laos">Laos</MenuItem>
                      <MenuItem value = "Latvia">Latvia</MenuItem>
                      <MenuItem value = "Lebanon">Lebanon</MenuItem>
                      <MenuItem value = "Lesotho">Lesotho</MenuItem>
                      <MenuItem value = "Liberia">Liberia</MenuItem>
                      <MenuItem value = "Libya">Libya</MenuItem>
                      <MenuItem value = "Liechtenstein">Liechtenstein</MenuItem>
                      <MenuItem value = "Lithuania">Lithuania</MenuItem>
                      <MenuItem value = "Luxembourg">Luxembourg</MenuItem>
                      <MenuItem value = "Macedonia">Macedonia</MenuItem>
                      <MenuItem value = "Madagascar">Madagascar</MenuItem>
                      <MenuItem value = "Malawi">Malawi</MenuItem>
                      <MenuItem value = "Malaysia">Malaysia</MenuItem>
                      <MenuItem value = "Maldives">Maldives</MenuItem>
                      <MenuItem value = "Mali">Mali</MenuItem>
                      <MenuItem value = "Malta">Malta</MenuItem>
                      <MenuItem value = "Marshall Islands">Marshall Islands</MenuItem>
                      <MenuItem value = "Mauritania">Mauritania</MenuItem>
                      <MenuItem value = "Mauritius">Mauritius</MenuItem>
                      <MenuItem value = "Mexico">Mexico</MenuItem>
                      <MenuItem value = "Micronesia">Micronesia</MenuItem>
                      <MenuItem value = "Moldova">Moldova</MenuItem>
                      <MenuItem value = "Monaco">Monaco</MenuItem>
                      <MenuItem value = "Mongolia">Mongolia</MenuItem>
                      <MenuItem value = "Montenegro">Montenegro</MenuItem>
                      <MenuItem value = "Morocco">Morocco</MenuItem>
                      <MenuItem value = "Mozambique">Mozambique</MenuItem>
                      <MenuItem value = "Myanmar,1), {Burma}">Myanmar (Burma)</MenuItem>
                      <MenuItem value = "Namibia">Namibia</MenuItem>
                      <MenuItem value = "Nauru">Nauru</MenuItem>
                      <MenuItem value = "Nepal">Nepal</MenuItem>
                      <MenuItem value = "Netherlands">Netherlands</MenuItem>
                      <MenuItem value = "New Zealand">New Zealand</MenuItem>
                      <MenuItem value = "Nicaragua">Nicaragua</MenuItem>
                      <MenuItem value = "Niger">Niger</MenuItem>
                      <MenuItem value = "Nigeria">Nigeria</MenuItem>
                      <MenuItem value = "Norway">Norway</MenuItem>
                      <MenuItem value = "Oman">Oman</MenuItem>
                      <MenuItem value = "Pakistan">Pakistan</MenuItem>
                      <MenuItem value = "Palau">Palau</MenuItem>
                      <MenuItem value = "Panama">Panama</MenuItem>
                      <MenuItem value = "Papua New Guinea">Papua New Guinea</MenuItem>
                      <MenuItem value = "Paraguay">Paraguay</MenuItem>
                      <MenuItem value = "Peru">Peru</MenuItem>
                      <MenuItem value = "Philippines">Philippines</MenuItem>
                      <MenuItem value = "Poland">Poland</MenuItem>
                      <MenuItem value = "Portugal">Portugal</MenuItem>
                      <MenuItem value = "Qatar">Qatar</MenuItem>
                      <MenuItem value = "Romania">Romania</MenuItem>
                      <MenuItem value = "Russian Federation">Russian Federation</MenuItem>
                      <MenuItem value = "Rwanda">Rwanda</MenuItem>
                      <MenuItem value = "St Kitts & Nevis">St Kitts & Nevis</MenuItem>
                      <MenuItem value = "St Lucia">St Lucia</MenuItem>
                      <MenuItem value = "Saint Vincent & the Grenadines">Saint Vincent & the Grenadines</MenuItem>
                      <MenuItem value = "Samoa">Samoa</MenuItem>
                      <MenuItem value = "San Marino">San Marino</MenuItem>
                      <MenuItem value = "Sao Tome & Principe">Sao Tome & Principe</MenuItem>
                      <MenuItem value = "Saudi Arabia">Saudi Arabia</MenuItem>
                      <MenuItem value = "Senegal">Senegal</MenuItem>
                      <MenuItem value = "Serbia">Serbia</MenuItem>
                      <MenuItem value = "Seychelles">Seychelles</MenuItem>
                      <MenuItem value = "Sierra Leone">Sierra Leone</MenuItem>
                      <MenuItem value = "Singapore">Singapore</MenuItem>
                      <MenuItem value = "Slovakia">Slovakia</MenuItem>
                      <MenuItem value = "Slovenia">Slovenia</MenuItem>
                      <MenuItem value = "Solomon Islands">Solomon Islands</MenuItem>
                      <MenuItem value = "Somalia">Somalia</MenuItem>
                      <MenuItem value = "South Africa">South Africa</MenuItem>
                      <MenuItem value = "South Sudan">South Sudan</MenuItem>
                      <MenuItem value = "Spain">Spain</MenuItem>
                      <MenuItem value = "Sri Lanka">Sri Lanka</MenuItem>
                      <MenuItem value = "Sudan">Sudan</MenuItem>
                      <MenuItem value = "Suriname">Suriname</MenuItem>
                      <MenuItem value = "Swaziland">Swaziland</MenuItem>
                      <MenuItem value = "Sweden">Sweden</MenuItem>
                      <MenuItem value = "Switzerland">Switzerland</MenuItem>
                      <MenuItem value = "Syria">Syria</MenuItem>
                      <MenuItem value = "Taiwan">Taiwan</MenuItem>
                      <MenuItem value = "Tajikistan">Tajikistan</MenuItem>
                      <MenuItem value = "Tanzania">Tanzania</MenuItem>
                      <MenuItem value = "Thailand">Thailand</MenuItem>
                      <MenuItem value = "Togo">Togo</MenuItem>
                      <MenuItem value = "Tonga">Tonga</MenuItem>
                      <MenuItem value = "Trinidad & Tobago">Trinidad & Tobago</MenuItem>
                      <MenuItem value = "Tunisia">Tunisia</MenuItem>
                      <MenuItem value = "Turkey">Turkey</MenuItem>
                      <MenuItem value = "Turkmenistan">Turkmenistan</MenuItem>
                      <MenuItem value = "Tuvalu">Tuvalu</MenuItem>
                      <MenuItem value = "Uganda">Uganda</MenuItem>
                      <MenuItem value = "Ukraine">Ukraine</MenuItem>
                      <MenuItem value = "United Arab Emirates">United Arab Emirates</MenuItem>
                      <MenuItem value = "United Kingdom">United Kingdom</MenuItem>
                      <MenuItem value = "United States">United States</MenuItem>
                      <MenuItem value = "Uruguay">Uruguay</MenuItem>
                      <MenuItem value = "Uzbekistan">Uzbekistan</MenuItem>
                      <MenuItem value = "Vanuatu">Vanuatu</MenuItem>
                      <MenuItem value = "Vatican City">Vatican City</MenuItem>
                      <MenuItem value = "Venezuela">Venezuela</MenuItem>
                      <MenuItem value = "Vietnam">Vietnam</MenuItem>
                      <MenuItem value = "Yemen">Yemen</MenuItem>
                      <MenuItem value = "Zambia">Zambia</MenuItem>
                      <MenuItem value = "Zimbabwe">Zimbabwe</MenuItem> 
                      </Select>

                      {errors.Country && (
                        <p className="text-error" style={{ fontSize: "10px" }}>
                          <br></br>Select Your Country
                        </p>
                      )}
                    </FormControl>
                    
                  )}
                  
                  

                  <FormControl sx={{ m: 1, minWidth: 180 }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Town/City
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={Area}
                      label="Tow/City"
                      {...register("Area", { required: true })}
                      onChange={handleAreaChange}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Ba">Ba</MenuItem>
                      <MenuItem value="Bua">Bua</MenuItem>
                      <MenuItem value="Cakaudrove">Cakaudrove</MenuItem>
                      <MenuItem value="Kadavu">Kadavu</MenuItem>
                      <MenuItem value="Korovou">Korovou</MenuItem>
                      <MenuItem value="Labasa">Labasa</MenuItem>
                      <MenuItem value="Lami">Lami</MenuItem>
                      <MenuItem value="Lau">Lau</MenuItem>
                      <MenuItem value="Lautoka">Lautoka</MenuItem>
                      <MenuItem value="Levuka">Levuka</MenuItem>
                      <MenuItem value="Lomaiviti">Lomaiviti</MenuItem>
                      <MenuItem value="Macuata">Macuata</MenuItem>
                      <MenuItem value="Makoi">Makoi</MenuItem>
                      <MenuItem value="Mamanuca">Mamanuca</MenuItem>
                      <MenuItem value="Nabouwalu">Nabouwalu</MenuItem>
                      <MenuItem value="Nabua">Nabua</MenuItem>
                      <MenuItem value="Nadi">Nadi</MenuItem>
                      <MenuItem value="Naitasiri">Naitasiri</MenuItem>
                      <MenuItem value="Nakasi">Nakasi</MenuItem>
                      <MenuItem value="Namosi">Namosi</MenuItem>
                      <MenuItem value="Nasinu">Nasinu</MenuItem>
                      <MenuItem value="Nausori">Nausori</MenuItem>
                      <MenuItem value="Navua">Navua</MenuItem>
                      <MenuItem value="Pacific Harbour">
                        Pacific Harbour
                      </MenuItem>
                      <MenuItem value="Ra">Ra</MenuItem>
                      <MenuItem value="Raiwaqa">Raiwaqa</MenuItem>
                      <MenuItem value="RakiRaki">RakiRaki</MenuItem>
                      <MenuItem value="Rewa">Rewa</MenuItem>
                      <MenuItem value="Rotuma">Rotuma</MenuItem>
                      <MenuItem value="Samabula">Samabula</MenuItem>
                      <MenuItem value="Savusavu">Savusavu</MenuItem>
                      <MenuItem value="Seaqaqa">Seaqaqa</MenuItem>
                      <MenuItem value="Serua">Serua</MenuItem>
                      <MenuItem value="Sigatoka">Sigatoka</MenuItem>
                      <MenuItem value="Suva">Suva</MenuItem>
                      <MenuItem value="Tailevu">Tailevu</MenuItem>
                      <MenuItem value="Taveuni">Taveuni</MenuItem>
                      <MenuItem value="Tavua">Tavua</MenuItem>
                      <MenuItem value="Valelevu">Valelevu</MenuItem>
                      <MenuItem value="Vatukoula">Vatukoula</MenuItem>
                      <MenuItem value="Yasawa">Yasawa</MenuItem>
                    </Select>
                    {errors.Area && (
                      <p className="text-error" style={{ fontSize: "10px" }}>
                        <br></br>Select Your Area
                      </p>
                    )}
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {" "}
                      Occupation Industry
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={OccupationIndustry}
                      label="Occupation Industry"
                      {...register("OccupationIndustry", { required: true })}
                      onChange={handleOccupationChange}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Science, Technology, Engineering and Mathematics">
                        Science, Technology, Engineering and Mathematics
                      </MenuItem>

                      <MenuItem value="Hospital and Healthcare">
                        Hospital and Healthcare
                      </MenuItem>

                      <MenuItem value="Tourism and Hospitality">
                        Tourism and Hospitality
                      </MenuItem>

                      <MenuItem value="Education and Training">
                        Education and Training
                      </MenuItem>
                      <MenuItem value="Manufacturing and Retail">
                        Manufacturing and Retail
                      </MenuItem>
                      <MenuItem value="Banking and Financial Services">
                        Banking and Financial Services
                      </MenuItem>
                      <MenuItem value="Accounting">Accounting</MenuItem>
                      <MenuItem value="Agriculture,Food/Beverage and Natural Resources">
                        Agriculture,Food/Beverage and Natural Resources
                      </MenuItem>
                      <MenuItem value="Architecture and Construction">
                        Architecture and Construction
                      </MenuItem>
                      <MenuItem value="Arts, Audio/Video Technology and Communication">
                        Arts, Audio/Video Technology and Communication
                      </MenuItem>
                      <MenuItem value="Government and Public Administration">
                        Government and Public Administration
                      </MenuItem>
                      <MenuItem value="Professional Sports">
                        Professional Sports
                      </MenuItem>
                      <MenuItem value="Telecommunications">
                        Telecommunications
                      </MenuItem>
                      <MenuItem value="Self Employed">
                        Self Employed
                      </MenuItem>
                      <MenuItem value="Others">
                        Others
                      </MenuItem>
                      <MenuItem value="Domestic Duties">
                        Domestic Duties
                      </MenuItem>
                     <MenuItem value="Transportation, distribution and logistics">
                        Transportation, distribution and logistics
                      </MenuItem>
                    <MenuItem value="Lawyers and Legal Services">
                        Lawyers and Legal Services
                      </MenuItem>
                    <MenuItem value="Inbound Tourist">
                        Inbound Tourist
                      </MenuItem>

                            <MenuItem value="Insurance services">
                        Insurance services
                      </MenuItem>

                            <MenuItem value="Primary School Student">
                        Primary School Student
                      </MenuItem>

                            <MenuItem value="Professional Sports">
                        Professional Sports
                      </MenuItem>

                            <MenuItem value="Public Safety, Corrections and Security">
                        Public Safety, Corrections and Security
                      </MenuItem>

                            <MenuItem value="Real Estate">
                        Real Estate
                      </MenuItem>

                            <MenuItem value="Religious and Community appointment">
                        Religious and Community appointment
                      </MenuItem>

                            <MenuItem value="Secondary School Student">
                        Secondary School Student
                      </MenuItem>

                            <MenuItem value="Tertiary School Student">
                        Tertiary School Student
                      </MenuItem>
                     
                      {/* <MenuItem value="Others">Others</MenuItem> */}
                    </Select>
                    {errors.OccupationIndustry && (
                      <p className="text-error" style={{ fontSize: "10px" }}>
                        <br></br>Select Your Occupation Industry
                      </p>
                    )}
                  </FormControl>

                  <FormControl sx={{ m: 0, minWidth: 300 }}>
                    <TextField
                      id="standard"
                      label="Residential Address"
                      defaultValue=""
                      value={ResidentialAddress}
                      size="large"
                      {...register("ResidentialAddress", { required: true })}
                      onChange={(e) =>
                        setResidentialAddress((v) =>
                          e.target.validity.valid ? e.target.value : v
                        )
                      }
                      // className="form-control"
                      // aria-describedby="emailHelp"
                    />
                    {errors.ResidentialAddress && (
                      <p
                        className="text-error"
                        style={{ fontSize: "10px", m: 1 }}
                      >
                        Enter Your ResidentialAddress
                      </p>
                    )}
                  </FormControl>
                </div>
              </Box>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                // Validate
                autoComplete="off"
              >
                <div>
                  <label className="mt-4 mb-4">
                    <b>Contact Information</b>
                  </label>
                </div>
                <div>
                  <FormControl>
                    <TextField
                      id="standard"
                      label="Email Address"
                      value={EmailAddress}
                      size="large"
                      {...register("EmailAddress", {
                        required: false,
                        maxLength: 100,
                      })}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      className="form-control"
                      aria-describedby="emailHelp"
                    />

                    {errors.EmailAddress && (
                      <p className="text-error" style={{ fontSize: "10px" }}>
                        <br></br>Invalid email address
                      </p>
                    )}
                  </FormControl>

                  <FormControl>
                    <TextField
                      id="standard"
                      label="Alternative Contact"
                      defaultValue=""
                      value={Contact}
                      size="large"
                      {...register("Contact", {
                        required: false,
                        maxLength: 7,
                      })}
                      onChange={(input) => {
                        onChangeContact(input.target.value);
                      }}
                      className="form-control"
                      aria-describedby="emailHelp"
                    />

                    {errors.Contact && (
                      <p className="text-error" style={{ fontSize: "10px" }}>
                        <br></br>Enter Your Contact
                      </p>
                    )}
                  </FormControl>
                  <br />
                  <br />
                  <div className="btn-toolbar gap-3">
                    <Link to="/validation">
                      <button className="btn btn-danger">Back</button>
                    </Link>

                    <Button
                      className=" btn btn-danger"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </Form>
      </React.Fragment>
    </Layout>
  );
};


